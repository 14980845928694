require("./bootstrap");
// window.$ = window.jQuery = require("jquery");

// require("alpinejs");

var Cleave = require("cleave.js");

require("bootstrap-select");
require("bootstrap-select/js/i18n/defaults-es_ES");

// require('bootstrap-table');
// require('tableexport.jquery.plugin/tableExport.js');
// require('bootstrap-table/dist/extensions/export/bootstrap-table-export.js');
// require('bootstrap-table/dist/bootstrap-table-locale-all.js');
// require('bootstrap-table/dist/extensions/filter-control/bootstrap-table-filter-control.js');
// require("popper.js");
